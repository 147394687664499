import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

Vue.config.productionTip = false

import firebase from 'firebase'
const firebaseConfig = {
  apiKey: "AIzaSyBp0WE03_rL07Yt7Rf6kIq1d99pgl3w-28",
  authDomain: "uniquenumber2-c6167.firebaseapp.com",
  projectId: "uniquenumber2-c6167",
  storageBucket: "uniquenumber2-c6167.appspot.com",
  messagingSenderId: "1008105658525",
  appId: "1:1008105658525:web:f37a1b3a373d90392adc74"
};

firebase.initializeApp(firebaseConfig);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
